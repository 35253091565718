body {
  /* background-color: #282c34; */
  background-color: #18181b;
  color: #FFF;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: 'SF Compact', 'Montserrat', sans-serif;
}
.App {
  /* text-align: center; */

}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  min-height: 100vh;
  /* max-width: 720px; */
  margin: auto;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
