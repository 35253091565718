:root {
  --ec-menu-height: 80px;
}

.swipeable-list-item {
  /* margin-top: 0.5rem;
  margin-bottom: 0.5rem; */
  background-color: #18181b;
  /* border-radius: 0.5rem; */
  /* color: #000; */
}
.swipeable-list-item__content {
  background-color: #18181b;
}
.chat-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: scroll;
  /* scroll-behavior: smooth; */
}
.message-list {
  padding-bottom: var(--ec-menu-height);
}

.header-menu, .footer-menu {
  font-size: 36px;
  z-index: 10;
}
.header-menu, .footer-menu .menu-options {
  height: var(--ec-menu-height);
}

.header-menu {
  position: sticky;
  top: 0;
}
.footer-menu {
  position: fixed;
  bottom: 0;
}
.btn-back-to-live {
  position: fixed;
  bottom: var(--ec-menu-height);
  right: 0px;
  z-index: 12;
}

.ec-profile-pic {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
  margin-left: 0.1rem;
  background-size: contain;
  position: relative;
}
.dot-container {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: -6px;
  right: -11.5px;
}
.dot {
  border: 3px solid #18181b;
  height: 14px;
  width: 14px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
}