@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  a { text-decoration: underline; }
  a:hover, a:active { opacity: 0.8; text-decoration: none; }
  textarea { resize: none; }
}
@layer components {
  .btn-fit {
    @apply w-full rounded-2xl shadow-sm p-4;
  }
  .btn-primary {
    @apply btn-fit bg-primary text-white;
  }
  .challenge-card {
    @apply rounded-2xl bg-gray-100 p-4;
  }
  .fit-log-icon {
    @apply transition-all;
    box-shadow: 0px;
  }
  .orange-glow {
    box-shadow: 0px 4px 16px rgba(255, 103, 31, 0.24), 0px 8px 24px rgba(255, 122, 60, 0.16), 0px 16px 32px rgba(255, 103, 31, 0.08);
  }
  .header-oldvsnew {
    background-color: #31499E;
    border-radius: 1rem;
    height: 280px;
  }
  .bg-oldvsnew-grad-top {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 1rem 1rem 0rem 0rem;
  }
  .bg-oldvsnew-grad-bottom {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    border-radius: 0rem 0rem 1rem 1rem;
  }
}
